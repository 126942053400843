import React from 'react'
import { get } from 'lodash'

import LeftArrow from 'src/assets/arrows-left.png'
import RightArrow from 'src/assets/arrows-right.png'
import {
  CarouselContainer,
  StyledCarousel,
  ArrowControlContainer,
  ArrowControl,
} from './Carousel.styled'

export class Carousel extends React.Component {
  componentDidMount() {
    setTimeout(() => {
      window.dispatchEvent(new Event('resize'))
    }, 0)
  }

  render() {
    return (
      <CarouselContainer isShort={this.props.isShort}>
        <StyledCarousel
          autoplay={false}
          wrapAround={true}
          frameOverflow="show"
          context={get(this.props, 'context')}
          renderCenterLeftControls={({ previousSlide }) => (
            <ArrowControlContainer onClick={previousSlide}>
              <ArrowControl src={LeftArrow} />
            </ArrowControlContainer>
          )}
          renderCenterRightControls={({ nextSlide }) => (
            <ArrowControlContainer onClick={nextSlide}>
              <ArrowControl src={RightArrow} />
            </ArrowControlContainer>
          )}
        >
          {this.props.children}
        </StyledCarousel>
      </CarouselContainer>
    )
  }
}
